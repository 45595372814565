<template>
  <div class="detail-container">
    <div class="operator-bar">
      <el-button type="primary" @click="handleUpdate">编辑</el-button>
      <el-button type="primary" :loading="loading == 'resetPassword'" @click="resetPassword">重置密码</el-button>
    </div>
    <div class="detail-info">
      <el-row>
        <el-col :span="3">用户名：</el-col>
        <el-col :span="21">{{info.userName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">手机号：</el-col>
        <el-col :span="21">{{info.phone}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">角色：</el-col>
        <el-col :span="21" v-if="info.role">{{info.role.roleName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">描述：</el-col>
        <el-col :span="21">{{info.description}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">状态：</el-col>
        <el-col :span="21">{{info.userStatus | formatStatus}}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getUserDetail, resetPassword} from '@/api/user'

export default {
  data() {
    return {
      userId: parseInt(this.$route.query.userId),
      info: {},
      loading: false
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getUserDetail({userId:this.userId}).then(res => {
        this.info = res.data
      })
    },
    handleUpdate() {
      this.$router.push('/account/user/edit?userId='+this.userId)
    },
    resetPassword() {
      this.loading = 'resetPassword'
      resetPassword({userId: this.userId}).then(res => {
        this.loading = false
        this.$message({
          message: '重置成功',
          type: 'success'
        })
      })
    }
  },
  filters: {
    // 状态显示转换
    formatStatus(status) {
      const statusMap = {
        'DISABLE': '停用',
        'NORMAL': '正常',
      }
      return statusMap[status]
    }
  }
}
</script>