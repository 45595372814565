import request from '@/utils/request'

// 用户列表
export function getUserList(params) {
  return request({
    url: '/api/user/list',
    method: 'get',
    params
  })
}

// 列表总数
export function getUserListCount(params) {
  return request({
    url: '/api/user/list/count',
    method: 'get',
    params
  })
}

// 列表详情
export function getUserDetail(params) {
  return request({
    url: '/api/user/detail',
    method: 'get',
    params
  })
}

// 新建
export function createUser(data) {
  return request({
    url: '/api/user/create',
    method: 'post',
    data
  })
}

// 修改
export function updateUser(data) {
  return request({
    url: '/api/user/update',
    method: 'post',
    data
  })
}

// 修改密码
export function changePassword(data) {
  return request({
    url: '/api/user/changePassword',
    method: 'post',
    data
  })
}

// 重置密码
export function resetPassword(data) {
  return request({
    url: '/api/user/resetPassword',
    method: 'post',
    data
  })
}





